import PropTypes from 'prop-types';
import DOMPurify from 'isomorphic-dompurify';
import truncate from 'html-truncate';

/**
 * Renders HTML content with optional sanitization and truncation.
 *
 * @param {Object} props
 * @param {string} props.html - The HTML content to be rendered.
 * @param {boolean} [props.sanitize=true] - Whether to sanitize the HTML content.
 * @param {number} [props.truncateLimit] - The limit at which to truncate the HTML content.
 * @returns {JSX.Element} The rendered HTML content inside a div.
 */
export function RichText({ html, sanitize = true, truncateLimit }) {
  const sanitizeConfig = {
    ADD_ATTR: ['target', 'rel'],
    ALLOW_UNKNOWN_PROTOCOLS: true,
  };

  let clean = sanitize ? DOMPurify.sanitize(html, sanitizeConfig) : html;

  if (truncateLimit) {
    clean = truncate(clean, truncateLimit);
  }

  return <div dangerouslySetInnerHTML={{ __html: clean }} />;
}

RichText.propTypes = {
  html: PropTypes.string,
  sanitize: PropTypes.bool,
  truncateLimit: PropTypes.number,
};

export default RichText;
